/**
 *
 * IndexPage
 *
 *
 */

 import React, { useEffect } from 'react';
 import styled from 'styled-components';
 
 import { graphql } from "gatsby";
 
 
 import ContentEngine from 'components/content/ContentEngine';
 import SectionHeadline from 'components/elements/SectionHeadline';
 import { LazyLoadImage } from 'react-lazy-load-image-component';
 
 
 const TenTenPage = ({ data }) => {
   useEffect(() => {
 
   });
   console.log(data);
   const { contentEngine } = data.contentEngine;
   
   return (
     <Container>
      <SplashContainer>
        <TextContainer>
          <Headline greenText tenTen>1010</Headline>
          <h3>A tribute to a legend.</h3>
          <DescriptorContainer>
            <p className={'bebas'}>Barrel Aged Stout</p>
            <p className={'bebas'}>14% ABV</p>
          </DescriptorContainer>
        </TextContainer>
        <div>
          <LazyLoadImage src={'/images/1010-bottle.png'} />
        </div>
      </SplashContainer> 
      <ContentEngine blocks={contentEngine}/>
     </Container>
   );
 };
 
TenTenPage.propTypes = {
 
 };
 
 export default TenTenPage;
 
 const Container = styled.main`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
   background-color: white;
   padding-bottom: 6em;
   padding-top: 4em;
 `;

 const SplashContainer = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 850px;
  width: 100%;
  > div {
    width: 100%;
  }
  @media all and (min-width: 600px) {
    > div {
      width: 50%;
    }
  }
  img {
    width: 100%;
    height: auto;
    max-width: 275px;
    display: block;
    margin: 2em auto;
  }
  @media all and (min-width: 600px) {
    img {
      max-width: 325px;
    }
  }
 `;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: -5em;
  width: 100%;
  h3 {
    font-family: 'Averia Serif Libre', serif;
    color: ${({ theme }) => theme.colors.Primary};
    font-size: 4em;
  }
  @media all and (min-width: 600px) {
    width: 50%;
    order: 2;
  }
`;
 
 const Headline = styled(SectionHeadline)`
  margin-top: 2em;
  &:after {
    bottom: 27.5%;
  }
  &:before {
    top: 32.5%;
  }
  @media all and (min-width: 900px) {
     h2 {
       font-size: 4.8em;
     }
   }
 `;

 const DescriptorContainer = styled.div`
 display: flex;
 margin-top: 2em;
 p {
   color: ${({ theme }) => theme.colors.Primary};
   position: relative;
   font-size: 2.2em;
   letter-spacing: 3px;
 }
 p:first-of-type {
   margin-right: 40px;
 }


 p:first-of-type::after {
   content:"";
   background-image: url('/icons/tee.svg');
   background-size: 100% auto;
   background-repeat: no-repeat;
   height: 100%;
   width: 5px;
   position: absolute;
   top: 3px;
   right: -20px;
 }

`;
 
 export const pageQuery = graphql`
   {
     contentEngine: craftTenTenTenTenEntry  {
        contentEngine {
          ... on Craft_contentEngine_richText_BlockType {
            typeHandle
            body
          }
          ... on Craft_contentEngine_blockQuote_BlockType {
            typeHandle
            citation
            quote
          }
          ... on Craft_contentEngine_videoEmbed_BlockType {
            typeHandle
            videoUrl
          }
          ... on Craft_contentEngine_headline_BlockType {
            typeHandle
            text
          }
          ... on Craft_contentEngine_lineBreak_BlockType {
            typeHandle
            icon {
              url
            }
          }
          ... on Craft_contentEngine_pullQuote_BlockType {
            typeHandle
            quote
          }
          ... on Craft_contentEngine_cta_BlockType {
            typeHandle
            textColor
            buttonColor
            buttonLink
            buttonText
            newTab
          }
          ... on Craft_contentEngine_photoGallery_BlockType {
            typeHandle
            slide {
              ... on Craft_slide_BlockType {
                id
                image {
                  url
                  focalPoint
                }
              }
            }
          }
          ... on Craft_contentEngine_imageOneUp_BlockType {
            typeHandle
            image {
              url
              focalPoint
            }
          }
          ... on Craft_contentEngine_imageTwoUp_BlockType {
            typeHandle
            images {
              ... on Craft_images_BlockType {
                id
                image {
                  url
                  focalPoint
                }
              }
            }
          }
        }
      }
   }
 `;